/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scroll-behavior: smooth;
  scrollbar-color: var(--borders) var(--BGColor);
}
:root {
  --HoverText: white;
  --borderRadius: 20px;
  --borderRadiusSmall: 10px;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}
.CAPTCHA {
  margin: 10px auto;
  width: fit-content;
}
.Button:hover {
  scale: 1.05;
}
.Button {
  cursor: pointer;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  border: none;
  color: var(--text);
  width: fit-content;
  font-size: 1rem;
  border-radius: 30px;
  font-weight: 600;
  transition: 0.2s all;
  display: flex;
  min-width: 144px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 4px;
}
.Button.Empty {
  background: linear-gradient(var(--BGColor), var(--BGColor)) padding-box,
    linear-gradient(60deg, var(--gradientColor1), var(--gradientColor1))
      border-box;
  border: 2px solid var(--borders);
  text-align: center;
  display: inline-block;
}
.Button.Empty span {
  font-size: 0.87rem;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bolder;
}
.Button.Empty img,
.Button.Empty svg {
  width: 15px;
  height: 15px;
  margin-bottom: 2px;
  margin-left: 5px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--borders);
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-VariableFont_slnt\,wght.ttf") format("woff2");
  font-weight: 100 200 300 400 500 600 700 800 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Helvetica/Helvetica.woff") format("woff");
}

body {
  font-family: var(--Font) !important;
  margin: 0;
  padding: 0;

  overflow-x: hidden !important;
}
h1,
h3,
p {
  color: var(--text);
  font-family: var(--Font);
  cursor: default;
}
.FADE {
  opacity: 0;
  z-index: -1 !important;
}
.PhotoEffect {
  overflow: hidden;
}

.PhotoEffect img:hover {
  transform: scale(1.05);
}
.PhotoEffect img {
  border-radius: 15px;
  transition: 0.2s all;
}
section {
  padding: 64px 96px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  margin: 0 auto !important;
  overflow-x: hidden;
}
blockquote {
  margin: 0;
  padding: 10px;
  border-left: 4px solid #ccc;
  background-color: #f5f5f5;
  font-style: italic;
}

blockquote p {
  margin: 0;
}
hr {
  width: 100% !important;
}
blockquote footer {
  margin-top: 10px;
  font-style: normal;
}

button,
h3,
h4,
h5,
h6,
p {
  color: var(--text);
  font-family: var(--Font) !important;
}
.carousel-inner .active {
  background-color: transparent !important;
}
.swiper-pagination-bullet {
  transition: 0.2s all;
  color: white !important;
  background: white !important;
}

.swiper-pagination-bullet-active {
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  ) !important;

  border-radius: 5px !important;
  width: 30px !important;
}
h2 {
  cursor: default;
  font-family: var(--Font);
  font-size: 2.6rem !important;
  color: var(--text) !important;
  font-weight: 300;
  margin-bottom: 30px !important;
}

.Link:focus,
.Link {
  margin-left: auto;
  margin-right: 20px;
  position: relative;
  text-decoration: none;
  width: fit-content;
  color: var(--text);
  padding: 20px;
  font-weight: 500;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 0.9rem;
}

.Link:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' fill='none' viewBox='0 0 20 9'%3E%3Cpath stroke='%2317161e' d='M0 4.547h19m0 0L15.544 1M19 4.547l-3.456 3.546'/%3E%3C/svg%3E");
  background-position: 100%;
  background-repeat: no-repeat;
  content: "";
  height: 25px;
  padding-left: 10px;
  padding-top: inherit;
  position: absolute;
  right: -7px;
  transition: background 0.1s ease-in-out;
  width: 20px;
  transition: 0.5s all;
}
.ql-align-center {
  text-align: center !important;
}
.ql-align-left {
  text-align: left !important;
}
.ql-align-right {
  text-align: right !important;
}
.Link.Reverse:after {
  transform: rotate(180deg);
  right: auto;
  left: -7px;
}
.Link.Reverse:hover:after {
  transform: translateX(-5px) rotate(180deg);
}
@media (max-width: 600px) {
  html {
    font-size: 12px;
  }

  section {
    padding: 40px 30px !important;
  }
  section {
    padding-right: 15px;
  }
}
