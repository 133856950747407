.Template12 .Navigator {
  background-color: var(--sidePagesHeaderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  border-radius: 15px;
}
.Template12 .Navigator .serviceLink {
  font-weight: 600;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Template12 .Navigator .serviceLink.Active {
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.Template12 h1 {
  color: var(--text);
}
.Template12 .Navigator .Active:after {
  content: "";
  bottom: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    60deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );

  left: 0;
}
.Template12 .overlay {
  border-radius: 16px;
  width: 500px !important;
  height: 80% !important;
  position: absolute;
  top: -0%;
  left: 7%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
  background-position: center;
}
.Template12 .Navigator .serviceLink p {
  width: 100%;
  text-align: center;
  color: white;
}
.Template12 .Navigator .mySwiper {
  width: 100%;
  margin: 0;
}
@media (max-width: 1000px) {
  .Template12 .Navigator {
    height: 125px;
  }
  .Template12 .overlay {
    width: 90% !important;
    left: 5%;
    margin: auto;
  }
}
