.Section4Duplicated {
  align-items: center;
  flex-direction: row-reverse;
  gap: 61px;
  justify-content: center;
  padding: 40px 96px !important;
  background-color: white;
}
.Section4Duplicated .Left {
  flex-direction: column;
  height: 100%;
  width: 100%;
  display: flex;
}
.Section4Duplicated .PhotoEffect {
  max-width: 50%;
  height: 440.977px;

  border-radius: var(--borderRadius);
}
.Section4Duplicated .PhotoEffect img {
  width: 100%;

}
.form-wrapper{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  flex-direction: row-reverse;
}
.Section4Duplicated .Left h2 {
  color: black;
  font-weight: bolder;
  text-align: left;
}
.Section4Duplicated .Left p {
  font-weight: 400;
  line-height: 26px;
  font-size: 1.2rem;
  margin-top: 20px;
  width: 80%;
  color: black;
  text-align: left;
}
.Section4Duplicated .ContactForm {
  position: unset;
  max-height: unset;
  width: 670px;
  overflow: hidden;
}
.Section4Duplicated .ContactForm button {
  margin: auto;
  width: 90%;
}


@media (max-width: 1000px) {
  .Section4Duplicated {
    padding: 40px 0px !important;
    overflow-y: hidden;
    flex-direction: column;
  }
  .Section4Duplicated .Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .Section4Duplicated .ContactForm {
    width: 100%;
  }
  .Section4Duplicated .Left h2 {
    text-align: center;
  }
  .Section4Duplicated .Left p {
    text-align: center;
    font-size: 14.4px;
    width: 100%;
  }
}
