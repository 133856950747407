.BasicHeader.Header {
  position: relative;
  width: 100%;
  height: 400px;
  z-index: 3;
  overflow-y: hidden;
}
.BasicHeader.Header .Shape {
  position: absolute;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  bottom: 0;
}
.Shape svg {
  width: 100%;
  height: auto;
}
.HelperShape {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 95%;
}
.BasicHeader.Header .Shape svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100%; /* Adjust height as needed */
}

.BasicHeader.Header .Content {
  z-index: 10;
  justify-content: flex-start;
  margin-top: 50px;
  gap: 30px;
}
.BasicHeader.Header .Content h1 {
  font-weight: 700;
  font-size: 2.625rem;
  line-height: 133.3%;
  text-align: center;

  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.BasicHeader.Header .Content p {
  font-weight: 500;
  font-size: 1rem;
  width: 60%;
  line-height: 161.3%;
  text-align: center;

  color: var(--text);
}
.breadcrumbs {
  height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.breadcrumb-item {
  display: flex;
  height: 20px;

  align-items: center;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}
.breadcrumb-separator {
  width: 4px;
  height: 7px;
  color: #b0b0b0; /* Adjust color as needed */
}
.breadcrumbs a {
  margin: 0 5px;
  height: 20px;
  font-weight: 500;
  text-decoration: none;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;}
.breadcrumbs .breadcrumb-item a {
  text-decoration: none;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background-clip: text;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .active {
  color: #6a0dad; /* Adjust color for active link */
}

@media (max-width: 400px) {
  .BasicHeader.Header .Content p {
    width: 90%;
  }
  .breadcrumbs {
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: center;
  }
  .breadcrumbs a {
    text-align: center;
  }
}
