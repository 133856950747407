.BlogMain {
  margin-top: 80px;
  padding: 40px 96px;
}
.ArticlePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ArticlePage h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 133.3%;
  text-align: left;
  color: #121212;
  width: 1223px;
}

.ArticlePage .Thumbnail {
  margin: 40px 0px;
  width: 1223px;
  background-color: var(--sidePagesHeaderColor);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  align-items: center;
}
.ArticlePage .Thumbnail img {
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  object-fit: contain;
}
.ArticlePage .ArticleBody {
  width: 1223px;
  margin: 30px 0;
}
.ArticlePage .Comments {
  background-color: var(--sidePagesHeaderColor);
  padding: 15px;
  border-radius: var(--borderRadius);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
}

.ArticlePage .ArticleDetails {
  padding: 15px;
  border-radius: var(--borderRadiusSmall);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  background-color: var(--sidePagesHeaderColor);
  color: var(--text) !important;
  align-items: center;
  justify-content: space-around;
  width: 1223px;
}
.ArticlePage .LikeButton {
  cursor: pointer;
  transition: 0.2s all;
}
.ArticlePage .LikeButton img:hover {
  scale: 1.1;
}
.ArticlePage .ArticleDetails span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ArticlePage .ArticleDetails span {
  font-size: 0.9rem;
}
.ArticlePage .ArticleDetails .icon {
  font-size: 1.2rem;
  margin-right: 5px;
}
.ArticlePage .CommentBody {
  border: 1px solid var(--borders);
  border-radius: var(--borderRadius);
  padding: 15px;
  margin: 10px;
  margin-top: 25px;
}
.ArticlePage .CommentBody h3 {
  margin-bottom: 0;
  font-weight: 200;
  font-size: 1.3rem;
}
.ArticlePage .CommentBody span {
  opacity: 0.7;
  font-size: 0.9rem;
  margin-left: 10px;
}
.ArticlePage .Navigation {
  width: 100%;
  margin: 30px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.ArticlePage .Navigation .Home {
  text-decoration: none;
  cursor: pointer;
  position: relative;
  margin: auto;
  color: var(--text);
  width: fit-content;
}

.ArticlePage .Navigation .Home:before {
  display: block;
  content: "";
  padding-top: 4px;
  border-bottom: solid 3px var(--LinkLines);
  transform: scaleX(0);
  transform-origin: 0 50%;
  left: 0;
  bottom: -5px;
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.ArticlePage .Navigation .Home:hover:before {
  transform: scaleX(1);
}

.ArticlePage .Navigation .Link {
  margin: 0;
}
.ArticlePage .CommentBody p {
  margin-top: 10px;
  font-size: 1rem;
  margin-left: 20px;
  width: 80%;
}
.AddComment {
  padding: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;
}
.AddComment .TextArea {
  width: 80%;
  padding: 15px;
  min-height: 56px;
}
.AddComment .TextArea .label.focused-label {
  background-color: var(--sidePagesHeaderColor);
}
.AddComment .TextArea textarea {
  resize: auto;
}
@media (max-width: 600px) {
  .BlogMain {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ArticlePage h1 {
    width: 100%;
    text-align: center;
    font-size: 3rem;
  }
  .ArticlePage .ArticleDetails {
    width: 100%;
    flex-wrap: wrap;
  }
  .BlogMain .ArticlePage,
  .BlogMain .ArticleBody {
    width: 100%;
    word-wrap: break-word;
  }
  .BlogMain .ArticlePage .Thumbnail {
    width: 100%;
    height: unset;
  }

  .BlogMain .ArticlePage .AddComment .Button {
    width: 49%;
    font-size: 1rem;
  }
  .BlogMain .ArticlePage .AddComment textarea {
    width: 100%;
  }
  .BlogMain .ArticlePage .Navigation {
    width: 70%;
  }
}
