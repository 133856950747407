.IconCard {
  width: 80%;
  margin: 20px auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  border-radius: var(--borderRadius);
  transition: 0.2s all;
  border: 2px solid transparent;
}
.IconCard:hover {
  border: 2px solid var(--borders);
}
.IconCard h4 {
  color: var(--text);
  text-transform: uppercase;
  margin-bottom: 20px;
  width: fit-content;
  padding: 10px;
}
.IconCard .Card-content {
  display: flex;
  gap: 10px;
}
.IconCard .Card-content img {
  width: 100px;
  height: 100px;
}
@media (max-width: 1000px) {
  .IconCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
  .IconCard h4 {
    width: 100%;
    text-align: center;
  }
  .IconCard .Card-content {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .IconCard .Card-content img {
    width: 70px;
    height: 70px;
  }
}
