.Card {
  position: relative;
  width: 300px;
  height: 118px;
  background: var(--BGColor);
  padding: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 1px solid var(--borders);
  display: flex;
  margin: 0 5px;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}
.Card .TopText {
  width: 100%;
}
.Card:before {
  display: block;
  content: "";
  padding-top: 4px;
  border-bottom: solid 4px var(--borders);
  transform: scaleX(0);
  transform-origin: 0 50%;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.MainNumber {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 52.08px;
  text-align: left;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.subText {
  position: relative;

  font-size: 1rem;
  font-weight: 900;
  line-height: 28.24px;
  text-align: left;
  color: var(--text);
}
.Line {
  display: block;
  border-bottom: solid 1px var(--borders);
  width: 100%;
  height: 2px;
  margin-bottom: 24px;
}
.Description {
  width: 90%;
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Description p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.TextWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1000px) {
  .Card {
    flex-direction: column;
    min-width: 270px;
    height: 150px;
    justify-content: center;
    align-items: center;
  }
  .subText {
    font-weight: 500;
    font-size: 1.3rem;
  }
  .Description {
    width: 100%;
  }
  .Description p {
    font-size: 1.2rem;
  }
}
