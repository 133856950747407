.Contact-Us {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Contact-Us .Contact-Info {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 64px 30px;
  height: fit-content;
}
.Contact-Us .Contact-Info .Info-Box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  padding: 0 20px;
  height: 80px;
  border-radius: 12px;
  color: var(--text)!important;
  background: #2c2c2c;
  box-shadow: 0px 0px 74px rgba(229, 221, 248, 0.5);
  border-radius: 12px;
  gap: 10px;
}
.Contact-Us .Contact-Info .Info-Box p {
  color: var(--text)!important;

  margin-bottom: 0;
}
.Contact-Us .Contact-Info .Info-Box .addressWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.Contact-Us .Contact-Info .Info-Box .addressWrapper p {
  margin-left: 20px;
  font-size: 0.9rem;
}
.Contact-Us .Contact-Info .Info-Box .addressWrapper h5 {
  font-weight: bolder;
  font-size: 1.2rem;
  margin-bottom: 0;
}
.Contact-Us .form-map .Left {
  width: 623px;
}
.Contact-Us .form-map {
  display: flex;
  width: 100%;
  padding: 64px 30px;
  gap: 32px;
  justify-content: center;
  align-items: flex-start;
}
.Contact-Us .form-map .Map {
  width: 556px;
}
.Contact-Us .ContactForm {
  position: unset;
  max-height: unset;
  overflow: hidden;
  overflow-x: hidden;
}
.Contact-Us .form-map .Left h2 {
  font-weight: 600;
}
.Contact-Us .ContactForm .Button {
  width: 85%;
  margin: auto;
  padding: 10px;
}
@media (max-width: 1000px) {
  .Contact-Us .Contact-Info {
    flex-direction: column;
    margin: 30px;
    gap: 20px;
    height: fit-content;
  }
  .Contact-Us .form-map {
    flex-direction: column;
  }
  .Contact-Us .Contact-Info .Info-Box,
  .Contact-Us .form-map .Map,
  .Contact-Us .form-map .Left {
    width: 95%;
    margin: auto;
  }
}
