.NavContainer {
  z-index: 4;
  background-color: var(--sidePagesHeaderColor);
  display: grid;
  height: 120px;
  width: 100%;
  padding-left: 7%;
  place-items: center;
  grid-template-columns: 26% 48% 26%;
  margin-left: auto;
  position: sticky;
  transition: 0.7s all;
}
.NavContainer-bg {
  position: fixed;
  height: 150px;
  top: 0;
  z-index: 4;
  width: 100%;
  padding-left: 7%;
  background-color: var(--BGColor);
}

.hidden {
  display: none !important;
}
.Logo {
  cursor: pointer;
  width: 200px;
  margin-right: auto;
}
.NavContainer-bg.Second {
  padding: 0 5%;
  width: 100% !important;
}
.NavContainer-bg .Logo {
  width: 220px;
}
.Logo img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.NavContainer a {
  text-decoration: none;
}
.navbar {
  border: 1px solid var(--borders);
  padding: 24px 50px !important;
  border-radius: 300px;
}
.navbar ul {
  gap: 25px;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
  transition: 0.2s all;
  border: 1.5px solid transparent;
}
.navbar a,
.navbar a:focus {
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--Font);
  font-size: 1rem;

  gap: 3px;
  color: var(--text);
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a:before {
  display: block;
  content: "";
  height: 2px;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  transform: scaleX(0);
  transform-origin: 0 50%;
  left: 0;
  bottom: -10px;
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.dropdown ul a:before {
  width: 100%;
  bottom: -5px;
}
.navbar a:hover:before {
  transform: scaleX(1);
}

.navbar a i,
.navbar a:focus i {
  font-size: 1.2rem;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: black;
}
.navbar a:hover {
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.navbar .dropdown ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  position: absolute;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  right: 50%;
  transform: translateX(50%);
  visibility: hidden;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  margin: 0;
  padding: 10px;
  background: var(--BGColor);
  width: fit-content;
  min-width: 800px;
  border-radius: 16px;
  padding: 24px;
}
.right {
  width: 24px;
  height: 24px;
  margin-right: 50px;
  margin-left: auto;
  margin-bottom: 4px;
}
.dropdown span img {
  transform: rotate(270deg);
  transition: 0.2s all;
}
.dropdown:hover span img {
  transform: rotate(360deg);
}
.right img {
  width: 100%;
  height: 100%;
}
.NavContainer.Second {
  background-color: var(--BGColor);
}
.NavContainer.Second {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: auto;
}
.NavContainer.Second #navbar a,
.NavContainer.Second #navbar a:focus,
.NavContainer.Second #navbar .dropdown span {
  color: black;
}
.NavContainer.Second .dropdown ul {
  filter: drop-shadow(0px 0px 75px rgba(221, 225, 248, 0.5));
}
.NavContainer.Second #navbar {
  border: 0;
  margin-right: 100px;
}
.navbar .dropdown ul .LinkBox {
  gap: 18px;
  width: 50%;
  height: fit-content;
  margin-top: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
}
.navbar .dropdown ul .LinkBox .Right {
  display: flex;
  margin: 5px 0;

  gap: 10px;
  flex-direction: column;
}

.navbar .dropdown ul .LinkBox .PageName {
  position: relative;

  font-size: 0.875rem;
  font-weight: 700;
  line-height: 13.93px;
  text-align: left;
}
.navbar .dropdown ul .LinkBox .Description {
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.88px;
  text-align: left;
}
.navbar .dropdown ul .LinkBox .left img {
  width: 27px;
  height: 27px;
}
.navbar .dropdown span {
  color: var(--text);
}
.navbar .dropdown ul a {
  font-size: 0.875rem;
  text-transform: none;
  color: var(--text);
  width: 100%;
}

.navbar .dropdown ul a i {
  font-size: 0.75rem;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.ActiveLink {
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.ActiveLink:before {
  display: block;
  content: "";
  height: 2px;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  transform: scaleX(1) !important;
  transform-origin: 0 50%;
  left: 0;
  bottom: -10px;
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 36px;

  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
@media (max-width: 1000px) {
  .Logo {
    max-width: 150px;
  }
  .NavContainer.Second #navbar {
    margin-right: 0;
  }
  .navbar .dropdown > ul {
    width: 100%;
    transform: translateX(0);

    right: 0;
    left: 0;
    min-width: fit-content;
  }
  .NavContainer {
    height: 100px;
    grid-template-columns: 50% 80%;
    position: sticky;
  }
  .SideMenu .navbar .dropdown:hover {
    flex-direction: column;
    align-items: center;
  }
  .SideMenu .navbar .dropdown:hover > ul {
    padding: 0;
    position: static;
    display: flex;
  }
}
.Pricing {
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  font-style: normal;
  line-height: 1.6;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  transition-timing-function: ease;
  z-index: 1;
  width: 130px;
  height: 50px;
  border-radius: 30px;
  border: transparent;
}
.Pricing:hover {
  color: white;
  background-color: var(--buttons);
}
