.Section2 {
  overflow-y: hidden;
  background: #f1f1f6;
  padding: 40px 96px !important ;
}

.Cards-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 1000px) {
  .Section2 {
    overflow-y: hidden;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0px !important;
  }
  .Section2 h2 {
    width: 100%;
  }
  .Cards-wrapper {
    width: 100%;
    height: 100%;
  }
  .swiper-wrapper {
    min-height: 200px;
  }
}
