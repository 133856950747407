.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1009;
}

.hamburger span {
  width: 100%;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: transform 0.3s, opacity 0.3s;
}

.hamburger.open span:first-child {
  transform: rotate(45deg) translate(6.5px, 6.5px);
  background-color: white;
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:last-child {
  transform: rotate(-45deg) translate(6.5px, -6.5px);
  background-color: white;
}
