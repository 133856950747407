.Section8 .WhyUs-Card {
  width: 256px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  gap: 16px;
  border-radius: var(--borderRadius);
  background-color: #1c1c1c;
  cursor: pointer;
  min-height: 260px;
  overflow: hidden;
  height: fit-content;
  gap: 7px;
}

.WhyUs-Card h4 {
  color: black;
  font-weight: bolder;
  font-size: 1rem;
  z-index: 2;
  transition: 0.2s ease-in-out all; /* Ensure the transition includes background and color */
}

.WhyUs-Card.active h4,
.WhyUs-Card.active span {
  color: var(--text);
  z-index: 2;
}
.WhyUs-Card.active {
  background-color: #1c1c1c;
  border: none !important;
}

.WhyUs-Card .cardBG {
  position: absolute;

  width: 100%;
  background: radial-gradient(
    circle at center,
    var(--gradientColor1) 0%,
    var(--gradientColor2) 100%
  );
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: center;
  animation: scaleToMid 0.4s ease-in-out both;
  opacity: 0;
}

@keyframes scaleToMid {
  0% {
    transform: scale(0);
    border-radius: 50%;
  }

  100% {
    border-radius: 50%;

    transform: scale(1.4);
    opacity: 1;
  }
}

@keyframes scaleFromMid {
  0% {
    transform: scale(1.4);
    border-radius: 50%;

    opacity: 1;
  }

  100% {
    transform: scale(0);
    border-radius: 50%;

    opacity: 0;
  }
}

.WhyUs-Card.active .cardBG {
  animation: scaleToMid 0.5s ease-in-out both;
  opacity: 1;
}

.WhyUs-Card .IMGWrapper {
  margin-bottom: 10px;
  z-index: 2;

  z-index: 2;
}

.WhyUs-Card .IMGWrapper,
.WhyUs-Card .IMGWrapper img {
  width: 40px;
  position: relative;
}

.WhyUs-Card span {
  width: 224px;
  height: fit-content;
  transition: 0.2s ease-in-out all; /* Ensure the transition includes background and color */
  font-weight: 400;
  font-size: 0.875rem;
  z-index: 2;

  line-height: 19px;
  color: var(--text);
}

@media (max-width: 1000px) {
  .WhyUs-Card {
    width: 300px;
    margin: 10px 0;
  }

  .WhyUs-Card span {
    width: unset;
    font-size: 1rem;
  }
}

/* Trigger animations on hover */
.WhyUs-Card:hover .cardBG {
  animation: scaleToMid 0.4s ease-in-out forwards;
}

.WhyUs-Card:not(:hover) .cardBG {
  animation: scaleFromMid 0.4s ease-in-out forwards;
}
