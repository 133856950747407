.NotFound {
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
  top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 200px;
}
.NotFound h1 {
  font-size: 10rem;
  font-weight: 300;
}
.NotFound p {
  font-size: 1.5rem;
  color: var(--text);
  text-transform: uppercase;
}
.NotFound button {
  width: 250px;
  border: none;
  height: 60px;
  background-color: green;
  color: white;
  border-radius: var(--borderRadius);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-transform: capitalize;
  transition: 0.2s all;
}
.NotFound button:hover {
  scale: 1.05;
}
.NotFound button:active {
  scale: 0.95;
}
.NotFound .Button-wrapper {
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
}
.NotFound .Button-wrapper button {
  width: 200px;
}
@media (max-width: 600px) {
  .NotFound .Button-wrapper {
    flex-direction: column;
    gap: 15px;
  }
}
