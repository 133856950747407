.Hosting {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95%;
}
.Hosting .Team-wrapper {
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.Hosting .PriceAdding {
  width: 60%;
  margin: auto;
}
.Hosting .PriceAdding .Button-wrapper {
  display: flex;
  justify-content: center;
}
.Hosting .Team-wrapper .Card-wrapper {
  width: 30%;
}
.HeaderContent {
  margin: 30px 0;
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
}
.HeaderContent .video,
.HeaderContent .textEditor {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.smallEditor .Editor {
  margin-top: 0;
  background-color: gray;
}
.SubmitWrapper {
  position: static;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: 0.5s all;
}
.fixed {
  backdrop-filter: blur(10px);
  position: sticky;
  bottom: 0px;
  padding-bottom: 10px;
  right: 38%;
}
.Hosting .EditorWrapper {
  display: grid;
  width: 95%;
  margin: auto;
  grid-template-areas:
    "First Second"
    "First Third";
  column-gap: 20px;
  height: fit-content;
  padding: 0 20px;
}
.Hosting .First .Editor {
  grid-area: First;
  margin: auto;
  max-width: unset;
}
.Hosting .EditorWrapper .Third {
  grid-area: Third;
}
.Hosting .EditorWrapper .Second {
  grid-area: Second;
}

.Hosting .EditorWrapper {
  border: 1px solid var(--borders);
  padding: 15px;
  border-radius: var(--borderRadius);
}

.Hosting .Team-wrapper .Button-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
}
.Hosting .Button-wrapper .View {
  width: fit-content;
}
.Hosting .Team-wrapper .Card-wrapper .TeamCard {
  width: 100%;
}
.PricingModal .modal-dialog {
  overflow-y: auto;
  margin: auto !important;
  width: 35% !important;
}
.PricingModal .modal-content {
  width: 100%;
}
.PricingModal .Pros-Adder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.PricingModal .Pros-Wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PricingModal .Pros-Wrapper ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.Hosting #Editor {
  grid-area: Editor;
  width: 90%;
  margin: auto;
}
.Hosting .Preview {
  margin-top: 20px;
  border: 1px solid var(--borders);
  border-radius: var(--borderRadius);
  background-color: gray;
  padding: 15px;
  width: 100%;
  word-break: break-word;
}
.Hosting #Editor #toolbar {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 1px solid var(--borders);
}
.Hosting #Editor .quill,
.Hosting #Editor .ql-container {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: none;
}
.Hosting #Editor ul {
  list-style: none !important;
}
.Hosting #Editor ul li {
  background-image: url("../../../../assets/mark-35780_640.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 30px;
}
.Hosting .Projects {
  width: 90%;
}
.Hosting .Projects .Categories {
  width: 90%;
}
.Hosting .Projects-wrapper {
  display: flex;
  gap: 40px;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: auto;
}
