.DataEntry {
  padding-top: 20px;
  padding-left: 0;
  display: grid;
  flex-wrap: wrap;
  width: 95%;
  grid-template-areas:
    "Title SubTitle"
    "btnText Photo"
    "Table Table"
    "Submit Submit ";
  justify-items: center; /* Center items horizontally */
}
.DataEntry .ColorPicker {
  margin-left: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid grey;
}
input[type="color"] {
  -webkit-appearance: none;
  border: none;

  cursor: pointer;
  border-radius: 50%;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 50%;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}
.DataEntry.Section1,
.DataEntry.Head,
.DataEntry.section4,
.DataEntry.section5,
.DataEntry.section6,
.DataEntry.section8 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.DataEntry.Head .CheckMarkWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DataEntry.Head .CheckMarkWrapper .CheckBox {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DataEntry .UploadWrapper {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  align-items: center;
}
.DataEntry.Head .form-check-input {
  width: 20px;
  height: 20px;
}

.DataEntry .video-progress-bar {
  width: 100px;
  margin-top: 10px;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: var(--borderRadiusSmall);
  margin-bottom: 10px;
}

.DataEntry .video-progress-bar-fill {
  height: 100%;
  background-color: #00aaff;
  border-radius: var(--borderRadiusSmall);
  transition: width 0.2s ease-in-out;
}
.DataEntry .Section-wrapper {
  border-radius: var(--borderRadius);
  width: 100%;
  padding: 15px;
  margin: 30px;
  border: 1px solid var(--borders);
}
.DataEntry .Pricing-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
  margin: auto;
}
.DataEntry .WebTheme ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.DataEntry .WebTheme ul li {
  width: 44%;
  display: flex;
}
.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  justify-content: center;
  align-items: center;
}
.EditorWrapper {
  width: 600px;
  max-height: 600px;
  overflow: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: var(--borderRadius);
}
.popup.open {
  display: flex;
}
.DataEntry .WebTheme ul li label {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DataEntry.Footer {
  display: flex;
  flex-direction: column;
}
.DataEntry.Footer .FirstPart,
.DataEntry.Footer .SocialLinks {
  margin: auto;
  width: 60%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5%;
}
.DataEntry.Footer .SocialLinks h3,
.DataEntry.Footer .FirstPart h3 {
  width: 100%;
  margin: 30px 0;
}
.DataEntry.Footer .SocialLinks .formItem input,
.DataEntry.Footer .FirstPart .formItem input {
  padding-left: 0;
  margin-left: 0;
}
.DataEntry.Footer .formItem.Address {
  border: 1px solid var(--Lines);
  padding: 15px;
  width: 100%;
  border-radius: var(--borderRadius);
}
.DataEntry.Footer .FirstPart .formItem,
.DataEntry.Footer .SocialLinks .formItem {
  width: 45% !important;
}
.DataEntry.section6 .Select-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
}
.DataEntry .FormItem,
.DataEntry .FormItem textarea {
  width: 80%;
}
.DataEntry.section5 .Button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DataEntry.section5 .Button-wrapper .View {
  width: fit-content;
}
.DataEntry .Add-Testimonial {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
}
.DataEntry .Add-Testimonial .FormItem {
  width: 50%;
}
.DataEntry .Add-Testimonial .FormItem.Description {
  width: 100%;
}
.DataEntry .Add-Testimonial .FormItem.Description textarea {
  width: 70%;
}
.modal-body .formItem {
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  display: flex;
  flex-direction: row;
}
.DataEntry #Title {
  grid-area: Title;
}
.DataEntry #SubTitle {
  grid-area: SubTitle;
}

.DataEntry #SubTitle {
  display: flex;
}

.DataEntry #ButtonText {
  grid-area: btnText;
}
.DataEntry #Submit {
  margin-top: 20px;
  grid-area: Submit;
}
.DataEntry #Photo {
  grid-area: Photo;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  width: 50%;
}
.DataEntry .FormItem,
.modal-body .formItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DataEntry input,
.DataEntry textarea,
.modal-body .formItem textarea,
.modal-body .formItem input {
  width: 100%;
  border: none;
  height: 50px;
}
.DataEntry textarea {
  margin-top: 0;
  height: 150px;
}
.DataEntry .View {
  width: 25%;
}
.DataEntry .Table {
  border-radius: 15px;
  margin-right: auto;
}
.DataEntry .OtherLinks {
  padding: 25px;
}
.DataEntry .LinkWrapper {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  gap: 30px;
}
.DataEntry .LinkWrapper .OtherLink,
.NewLink .OtherLink {
  height: fit-content;
  border: 1px solid transparent;
  transition: 0.5s all;
  margin-top: 20px;
  width: 90%;
  padding: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: var(--borderRadius);
}
.DataEntry .LinkWrapper .OtherLink:hover,
.NewLink .OtherLink:hover {
  border: 1px solid var(--LinkLines);
}

.DataEntry .CheckBox label,
.NewLink .CheckBox label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.DataEntry .LinkWrapper .OtherLink .Error,
.NewLink .OtherLink .Error {
  color: red;
  text-align: center;
}
.DataEntry .CheckBox label input,
.NewLink .CheckBox label input {
  height: 20px;
  width: 20px;
  margin: 0 auto;
}
.form-check-input[type="checkbox"],
.NewLink .form-check-input[type="checkbox"] {
  border: 1px solid #a4aab3;
}
.DataEntry .Button-wrapper .FormItem label {
  cursor: pointer;

  border: 1px solid green;
  color: black;
  width: fit-content;
  font-size: 1rem;
  border-radius: var(--borderRadiusSmall);
  padding: 5px;
  font-weight: 600;
  transition: 0.2s all;
}
.DataEntry .Button-wrapper .FormItem label:hover {
  background-color: green;
  color: white;
}
.PageSort {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PageSort span {
  opacity: 0.7;
  font-size: 0.8rem;
}
.Selects-wrapper {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  align-items: space-between; /* Align items vertically */
  justify-items: space-between; /* Align items horizontally */
}
.PageSort .select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.boxWrapper {
  position: relative;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 97%;
  margin: 40px auto;
  border: 1px solid var(--Lines);
  border-radius: var(--borderRadius);
  padding: 10px;
  padding-left: 20px;
  padding-top: 20px;
  margin-top: 20px;
}
.Template11 .boxWrapper,
.DataEntry.section8 .boxWrapper {
  width: 90%;
}
.boxWrapper .BoxTitle {
  user-select: none;
color: black!important;
  background-color: white;
  position: absolute;
  top: -15px;
  left: 18px;
}
