.TemplateGeneral {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  width: 87%;
  margin: auto;
  padding: 30px 0px;
}
.TemplateGeneral h2 {
  font-size: 2rem;
}

.TemplateGeneral h4 {
  font-size: 1.25rem;
}
/* .TemplateGeneral h3::after {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #e0e1e1;
} */
.TemplateGeneral p {
  font-size: 1.1rem;
  color: var(--text);
  line-height: 29px;
  margin-bottom: 0;
}

.TabItem {
  background-color: var(--BGColor);
  padding: 10px 20px;
  border-radius: var(--borderRadius);
  color: var(--text);
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 2px solid transparent;
  transition: 0.2s all;
}
.TabItem:hover {
  border: 2px solid var(--borders);
}

.TemplateGeneral ul {
  list-style: none; /* Remove default list style */
  padding: 0;
}

.TemplateGeneral ul li {
  position: relative;
}
.TemplateGeneral ul li p {
  margin-left: 20px;
}
.active {
  background-color: green;
  color: white;
  border: 2px solid transparent !important;
}

.TemplateGeneral .Roles {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  border-radius: var(--borderRadius);
  display: flex;
  gap: 30px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
}

.TemplateGeneral .Roles .swiper-button-prev:after,
.TemplateGeneral .Roles .swiper-button-next:after {
  font-size: 1rem !important;
}
.TemplateGeneral .Roles .Slider {
  padding-left: 20px;
  padding-right: 20px;
}

.Template13,
.Template12 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
h1,h2,h3,h4,h5,h6 {
  color: var(--text)!important;
}
.Template13 h1,
.Template12 h1 {
  font-weight: 700;
  font-size: 2.8rem;
}
.Template13 h1 {
  max-width: 50%;
  text-align: center;
  line-height: 133.3%;
  color: #121212;
  margin: 40px auto;
  width: 100%;
}

@media (max-width: 1000px) {
  .Template13 h1,
  .Template12 h1 {
    max-width: 100%;
    font-size: 2rem;
    margin: 20px;
  }
  .TemplateGeneral .Category-swiper .swiper-wrapper {
    min-height: unset;
  }

  .TemplateGeneral p {
    margin-left: 0;
  }
  .TemplateGeneral {
    padding-bottom: 0;
  }

  .TemplateGeneral .Sec3 ul {
    flex-wrap: wrap;
    gap: 10px;
    width: 100% !important;
    justify-content: flex-start;
  }
  .TemplateGeneral .Sec3 ul li {
    text-align: center;
    width: 40%;
  }

  .TemplateGeneral .Sec7 ul {
    max-height: none;
  }

  .TemplateGeneral .Roles {
    gap: 5px;
  }
}
