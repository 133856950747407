.Section3 {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px !important ;
  align-items: flex-start;
}
.Section3 h2 {
  margin-bottom: 3rem !important;
}

.Swiper {
  height: 300px;
  max-width: 100%;
}
.swiper {
  height: 100%;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  width: 200px;
}
