.modal {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.carousel {
  margin-top: 20px;
  width: 80%;
  height: 100%;
  border-radius: var(--borderRadius);
}
.carousel-inner {
  height: 100%;
}
.carousel-inner img {
  margin: auto;
  max-width: 80%;
  border-radius: var(--borderRadius);
  max-height: 400px;
  object-fit: contain;
  min-height: 400px;
}

.modal-dialog {
  margin: 0 auto;
  overflow: auto;
  max-width: unset;
  min-width: 1000px;
  display: flex;
  height: 97vh;
  border-radius: var(--borderRadius);
}
.ProjectPopupWrapper {
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  background-color: var(--BGColor);
flex-direction: column;
  top: 0px;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}
.ProjectPopupWrapper h3 {
  margin-bottom: 20px;
}
.ProjectPopupWrapper p {
  color: var(--text);
}
.modal-body {
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.modal-header {
  position: absolute;
  right: 0;
  z-index: 999;
  border: none;
}
.btn-close {
  background: url(../../../assets/x.svg) !important;
}
.ProjectData {
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1000px) {
  .modal-content {
    height: 100vh;
  }
  .carousel {
    margin-top: 50px;
    width: 100%;
    padding-bottom: 30px;
    height: fit-content;
  }
  .ProjectData {
    padding-left: 20px;
  }
  .ProjectPopupWrapper {
    flex-direction: column;
    left: 10px;
  }
  .modal-dialog {
    width: 93vw;
    overflow: unset;
  }
  .carousel-inner img {
    min-height: 200px;
    max-height: 200px;
  }
  .btn-close {
    background: url(../../../assets/x\ black.svg) !important;
  }
}
