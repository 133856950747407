.Loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  background-color: #2c2c2c;
  overflow: hidden;
  transition: 0.2s all;
}
