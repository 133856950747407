.form-group {
  position: relative;
  width: 100%;
  border-radius: 30px;
  border: 1px solid var(--borders);
  margin: 20px 0;
  height: 40px;
  background-color: transparent;
}
.form-group.textarea {
  height: 120px;
}
.form-group .label {
  color: var(--text);
  position: absolute;
  left: 8px;
  margin-left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  transition: all 0.2s ease-out;
}
.showPassword {
  position: absolute;
  top: 30%;
  right: 9px;
}
.form-group .input-lg,
.form-group div {
  background-color: transparent;
  border: none;
  width: 100%;
  color: white;
  height: 100%;
  padding-left: 8px; /* Adjust as needed */
}

.form-group .input-lg:focus {
  outline: none;
}

.label.focused-label {
  font-size: 0.9rem;
  top: -12px;
  background-color: var(--BGColor);
  transform: translateY(0);
}
