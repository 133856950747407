.ProductVideo {
  width: 80%;
  margin: auto;
}
/* .Product .RightSide {
  width: 45%;
} */
.Product ul,
.Product ol {
  padding-left: 30px;
  margin-top: 20px;
}

.Product .OverView p {
  margin-left: 0;
}
/* .Product .SellerData {
  display: Flex;
  flex-direction: column;
  width: 100%;
} */
/* .Product h2 {
  margin-left: 0;
} */
/* .Product .SellerData div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid var(--buttons);
  padding: 20px 10px;
} */
.Product ul {
  width: 100%;
  list-style: circle;
}
.Product {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: auto;
  position: relative;
}
.Effect {
  z-index: 1;
  position: absolute;
  width: 292px;
  height: 451px;
}
.Effect.one {
  right: 0;
  top: 0;
}
.Effect.two {
  left: 0px;
  top: 40%;
  transform: rotate(180deg);
}
.Product .LeftSide {
  width: 100%;
  padding: 60px 40px;
  padding-top: 0;
  width: 80%;
  margin: auto;
}
.Product .Product-Header {
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px 40px;
}
.Product .Product-Header .ProductTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 2.625rem;
  line-height: 133.3%;
  text-align: center;
  position: relative;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.Product .Product-Header .ProductTitle:after {
  position: absolute;
  content: " ";
  width: 100%;
  height: 4px;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  bottom: 0;
  left: 0;
}
.Product .Product-Header p {
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  max-width: 80%;
  text-align: center;
  color: #414141;
  margin-top: 15px;

  margin-bottom: 40px;
}
.Product .Product-Header .BG {
  border-radius: 13px;
  width: 80%;
  height: unset;
}
/* .Additional form {
  display: grid;
  grid-template-areas:
    "Fname Lname"
    "JobTitle Company"
    "Email Phone"
    "Country Country"
    "FollowUp FollowUp"
    "Document Document"
    "Submit Submit"
    "disclaimer disclaimer";
  gap: 20px;
}
.Additional form #Fname {
  grid-area: Fname;
}
.Additional form #Lname {
  grid-area: Lname;
}
.Additional form #disclaimer {
  grid-area: disclaimer;
  margin: 0;
  text-align: left;
}
.Additional form #Company {
  grid-area: Company;
}
.Additional form #JobTitle {
  grid-area: JobTitle;
}
.Additional form #Email {
  grid-area: Email;
}
.Additional form #Country {
  grid-area: Country;
}
.Additional form #Phone {
  grid-area: Phone;
}
.Additional form #FollowUp {
  grid-area: FollowUp;
}
.Additional form #Document {
  grid-area: Document;
}
.Additional p {
  margin-left: 0;
}
.Additional .Submit {
  grid-area: Submit;
  border: 1px solid var(--borders);
  background-color: transparent;
  height: 50px;
  color: var(--text);
  transition: 0.2s all;
  width: 100%;
  margin: 10px 0;
}
.Additional .Hint {
  border: 1px solid var(--borders);
  border-radius: var(--borderRadius);
  margin: 20px 0;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.Additional .Submit:hover {
  border-radius: var(--borderRadiusSmall);
  scale: 1.02;
}
.Additional span {
  display: block;
  font-size: 0.8rem;
  opacity: 0.7;
  width: 100%;
  text-align: center;
}
.Additional form .formItem input,
.Additional form .formItem .Select {
  width: 100%;
  margin: 10px 0 20px 0;
  border: none;
  border-bottom: 1px solid var(--buttons);
  height: 50px;
}
.OverView,
.Support,
.Highlights,
.Additional,
.SellerData {
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: 1px solid var(--borders);
  margin: 20px 0;
  text-align: left !important;

  padding-left: 25px;
  border-radius: var(--borderRadius);
} */
.no-wrap {
  white-space: normal !important;
  overflow: auto !important;
  text-overflow: unset !important;
  text-align: center !important;
}

@media (max-width: 1000px) {
  .Template13 .ProductCard {
    width: 60%;
    min-height: 200px;
  }
  .Product {
    padding: 0;
  }
  .Product .Product-Header {
    padding: 40px 30px !important;
  }
  .ProductVideo {
    width: 100%;
  }
  .Product .Product-Header .BG {
    width: 100%;
    height: unset;
  }
  .Product .LeftSide {
    width: 100%;
  }
  .Product .RightSide {
    width: 100%;
  }
}
