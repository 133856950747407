.CareerContent {
  display: flex;
  margin-top: 100px;
  align-items: flex-start;
  width: 100%;
}
.ViewJobs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.BelowJobs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
}
.BelowJobs h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 36px;
}
.BelowJobs p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 161.3%;

  color: #414141;
}
.Header-Career {
  display: flex;
  top: -150px;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 513px;
}
.Header-Career h1 {
  z-index: 3;
  color: white;
}
.Header-Career .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.39);
  top: -10%;
}
.Header-Career img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  top: -10%;
}

@media (max-width: 1000px) {
  .CareerContent {
    flex-direction: column;
    gap: 30px;
  }
  .CareerContent .ViewJobs {
    width: 100%;
  }

  .CareerContent .sidebarCareer {
    width: 100%;
  }
  .CareerContent .sidebarCareer::after {
    right: unset;
    width: 100%;
    height: 2px;
    left: 0;
  }
  .BelowJobs {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }
}
