.Portfolio .Categories {
  flex-direction: row;
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  box-shadow: none;
}
.Portfolio .Projects-wrapper {
  display: flex;
  gap: 40px;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin: auto;
}
@media (max-width: 1000px) {
  .Portfolio .Categories {
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
  }

  .Portfolio .Categories li {
    width: 45%;
    text-align: center;
  }
}
