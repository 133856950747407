.Dashboard {
  display: flex;
  width: 106vw;
  height: auto;
  margin-bottom: 50px;
  background-color: white!important;
}
.Dashboard .SideBar h3 {
  font-weight: 300;
}
.Dashboard .form-group .input-lg, .form-group div,
.modal-body .form-group .input-lg, .form-group div{
  color: black;
}
.Dashboard .SideBar {
  margin-top: 15px;
  border-right: 1px solid var(--Lines);
  max-width: 70px;
  padding-right: 20px;
  padding-left: 20px;
  height: unset;
  gap: 0;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.2s all;
  overflow: hidden; /* Hide any overflowed content */
}
.Dashboard .Expanded {
  max-width: fit-content;
  overflow: unset;
}
.Dashboard .SideBar .Icon {
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  border-left: 1px solid transparent;
  max-width: 23px;
}
.Dashboard .SideBar .focus.Icon {
  border-left: 1px solid var(--Lines);
  padding-left: 3px;
}
.Dashboard .SideBar .Icon:hover {
  scale: 1.1;
}
.Dashboard .SideBar .Greet span {
  display: block;
  word-wrap: break-word;
  width: 140px;
}
.Dashboard .SideBar .Greet {
  font-size: 0.9375rem;
  width: 100%;
  position: relative;
  margin: 30px auto;
  margin-top: 0;

  text-align: center;
  padding-top: 20px;
}
.Dashboard .SideBar .Link {
  padding-left: 0;
}
.Dashboard .SideBar .Link:before {
  left: 0;
}
.Dashboard .label.focused-label,
.modal-body .label.focused-label{
  background-color: white;
  color: black;
}
.Dashboard .form-group .label
,
.modal-body  .form-group .label{
  color: black;
}
.Dashboard h1,.Dashboard h3,.Dashboard p{
  color: black!important;
}
.Dashboard .Link:focus,.Dashboard .Link{
  color: black;
}
/* .Dashboard input:focus,
.Dashboard textarea:focus {
  outline: 1px solid var(--borders) !important;
  border-radius: var(--borderRadiusSmall);
} */
.Reject {
  width: 70%;
  margin: 0 auto;
  margin-top: 60px;
  text-align: center;
}
.Dashboard .SideBar .Greet::after,
.Dashboard .SideBar .Greet::before {
  content: "";
  background-color: var(--Lines);
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -10px;
}

.Dashboard .SideBar .Greet::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: -15px;
}
.Dashboard .Main {
  width: 80%;
}
.Dashboard .BTNList {
  list-style: none;
  padding: 0;
}
.Dashboard .BTNList span {
  padding-left: 20px;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 300;
  cursor: pointer;
}
.Dashboard .BTNList ul {
  list-style: none;
  padding: 0;
}
.Dashboard .BTNList li {
  margin: 15px 0;
}
.Dashboard .BTNList h3:hover {
  color: #ee233a;
}
.Dashboard .BTNList .focus:before {
  transform: scaleX(1);
}
.Dashboard .BTNList .focus:after {
  right: -20px !important;
}

.Dashboard .Page {
  max-width: 100vw;
  flex-grow: 1;
  padding-right: 90px;
}
.Dashboard .Settings {
  padding-top: 0;
  gap: 30px;
}
.Dashboard .Portal {
  padding-top: 0;
}
.Dashboard .Button,
.modal-body .Button {
  padding: 5px;
  background-color: transparent;
  color: white;
  min-width: unset;
}
.Dashboard .select-container {
  position: relative;
  display: inline-block;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.Dashboard .Reverse {
  margin-left: 30px;
  margin-right: auto;
  cursor: pointer;
}
.Users {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.Users .Button.View {
  margin: auto;
}
.Dashboard .styled-select {
  appearance: none;
  padding: 8px 16px;
  font-size: 1rem;
  border: 1px solid var(--buttons);
  border-radius: 4px;
  background-color: #f8f8f8;
  color: #555;
  cursor: pointer;
  width: fit-content;
}
.Dashboard .Sort {
  border: none;
}
.Dashboard .Sort:hover img {
  max-width: 35px;
}
.Dashboard .Sort:active img {
  max-width: 25px;
}
.Dashboard .Sort img {
  transition: 0.2s all;
  max-width: 30px;
}
.Dashboard .Button:hover,
.modal-body .Button:hover {
  scale: 1;
}
.Dashboard .Danger,
.modal-body .Danger {
  margin-left: 20px;
  border: 1px solid #9b1e2b;
  background: linear-gradient(90deg, #ff0000 -21.37%, #800000 97.98%);
}
.Dashboard .Danger:hover,
.modal-body .Danger:hover {
  background-color: #d9534f;
  color: white;
  border-radius: var(--borderRadiusSmall);
}

@media (max-width: 1000px) {
  .Dashboard {
    width: 100vw;
  }
}
