.Template15 .description {
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 160.3%;
  width: 70%;
  margin: auto;
  text-align: center;
  color: var(--text);
  margin-bottom: 30px;
}
.Position-Dropdown {
  
  margin: 20px 0;
}
.Position-Dropdown .css-13cymwt-control {
  background-color: var(--BGColor);
border: 1px solid var(--borders);
  border-radius: 30px;
}
.Template15 .ContactForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.Template15 .ContactForm button {
  width: 100%;
  margin: 20px 0;
}
.Template15 .Contact-Info {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 64px 30px;
  gap: 32px;
  height: fit-content;
}
.Template15 .Contact-Info .Info-Box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  padding: 0 20px;
  height: 80px;
  border-radius: 12px;
color: var(--text);
  background: var(--BGColor);
  box-shadow: 0px 0px 74px rgba(229, 221, 248, 0.5);
  border-radius: 12px;
  gap: 10px;
}
.Template15 .Contact-Info .Info-Box p {
  margin-bottom: 0;
  color: var(--text);
  font-size: 1rem;
}
.Template15 .Contact-Info .Info-Box .addressWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.Template15 .Contact-Info .Info-Box .addressWrapper p {
  margin-left: 20px;
  font-size: 0.9rem;
}
.Template15 .Contact-Info .Info-Box .addressWrapper h5 {
  font-weight: bolder;
  font-size: 1.2rem;
  margin-bottom: 0;
}
.row-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 80px;
}
@media (max-width: 1000px) {
  .Template15 .description {
    width: 90%;
  }
  .Contact-Info {
    flex-direction: column;
    margin: 30px;
    gap: 20px;
    height: fit-content;
  }
  .row-wrapper {
    flex-direction: column;
  }
}
