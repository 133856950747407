.Sec3 ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  width: 100% !important;
}
.Sec3 ul li {
  cursor: pointer;
  width: fit-content;
  padding: 10px;
  border-radius: var(--borderRadius);
  transition: 0.5s all;
  border: 4px solid transparent;
}
.Sec3 ul li:hover {
  scale: 1.08;
}
.Sec3 .Content {
  min-width: 95vw;
  display: flex;
  margin: auto;
  flex-direction: column;
}
.Sec4 ul,
.Sec5 ul,
.Sec6 ul {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 500px;
  gap: 5%;
}

.Sec4 li,
.Sec5 li,
.Sec6 li,
.Sec7 li {
  background-image: url("../../../assets/mark-35780_640.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 30px;
}
.Sec7 ul {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 200px;
  justify-content: center;
  gap: 20px;
}
.Sec5 ul,
.Sec6 ul,
.Sec7 ul {
  width: 100%;
}
@media (max-width: 1000px) {
  .TemplateGeneral .Sec3 .Content {
    margin: 0;
    min-width: 85vw;
  }

  .TemplateGeneral .Sec4 .List-wrapper {
    flex-direction: column;
  }
  .TemplateGeneral .Sec4 .List-wrapper ul {
    width: 100%;
  }
  .Sec4 ul,
  .Sec5 ul,
  .Sec6 ul {
    flex-direction: row;
    width: 100%;
    max-height: none;
  }
}
