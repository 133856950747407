.Settings {
  display: flex;
  padding-top: 80px;
}
.Settings .SideBar {
  width: 12%;
}

.Settings .Main {
  width: 65%;
}
.Settings .BTNList {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Settings .General form {
  display: grid;
  gap: 50px;
  margin: 30px auto;
  margin-right: 120px;
  padding: 20px 0;
  border-radius: var(--borderRadius);
  place-items: center;
  width: 90%;
  grid-template-areas:
    "Fname Lname"
    "Username Phone"
    "Email Email"
    "Gender DOB"
    "Save Save";
}
.Settings .General #Fname {
  grid-area: Fname;
}
.Settings .General #Lname {
  grid-area: Lname;
}
.Settings .General #Username {
  grid-area: Username;
}
.Settings .General #Phone {
  grid-area: Phone;
}
.Settings .General #Email {
  grid-area: Email;
}

.Settings .General #Gender {
  grid-area: Gender;
}
.Settings .General #DOB {
  grid-area: DOB;
}
.Settings .General #Save {
  grid-area: Save;
}
.Settings .General div {
  width: 100%;
}
.Settings .General label,
.Settings .Privacy label {
  display: block;
  margin-bottom: 5px;
}

.Settings .General select,
.Settings .General input[type="date"] {
  margin: 10px 0 20px 0;
  border: none;
  border-bottom: 1px solid var(--buttons);
  height: 50px;
  width: 100%;
}
.Settings input:focus {
  outline: none;
}

.Settings .Privacy .Button-Wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
  width: 50%;
  margin: auto;
  margin-top: 100px;
}
.Settings .Privacy .form-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 10px;
  margin: auto;
}
.Settings .Privacy input {
  width: 50%;
}
@media (max-width: 600px) {
  .Settings {
    flex-direction: column;
  }
  .Settings .SideBar {
    width: 100%;
  }
  .Settings .BTNList {
    flex-direction: row;
  }
  .Settings .Main {
    padding-left: 10px;
    width: 100%;
  }
}
