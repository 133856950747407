.TeamCard {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 550px;
  border: 3px solid transparent;
  transition: 0.2s all;
  color: var(--text);
  background-color: var(--BGColor);
  border-radius: var(--borderRadius);
}
.TeamCard:hover {
  border: 3px solid var(--borders);
}
.TeamCard .Photo {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  cursor: pointer;
  height: 70%;
  min-height: 70%;
}
.TeamCard .Photo img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: 1s all;
}

.TeamCard .Details .Name {
  width: 100%;
  padding-top: 5px;
  text-align: center;
  background: var(--BGColor);
}
.TeamCard .Details .Name p {
  color: white;

  font-weight: bolder;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 1.3rem;
}
.TeamCard .Details .Extra {
  text-align: center;
  overflow: hidden;
  width: 100%;
  padding: 5px;
}
.TeamCard .Details .Extra .text-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.TeamCard .Details .Role {
  width: 100%;
  text-align: center;

  font-size: 1rem;
  opacity: 0.7;
  margin: 0;
}
.TeamCard .Details {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  height: 130px;
}
.TeamCard .Details img {
  max-width: 25px;
  max-height: 25px;
}
@media (max-width: 1000px) {
  .TeamCard {
    width: 100%;
  }
}
