.ProjectCard {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius);
  margin: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.5s all;
  cursor: pointer;
  padding: 15px;
}
.ProjectCard:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ProjectCard img {
  object-fit: contain;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 90%;
}
.ProjectCard p {
  margin: 0 !important;
  padding: 0;
}
