.cookie-popup .Link:after {
  display: none;
}

.overlayCookie {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1000;
}
.cookie-popup p {
  margin-top: 0;
}
.cookie-popup h4 {
  font-weight: bolder;
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
}
.cookie-popup {
  width: 50%;
  min-height: 50%;
  background-color: var(--BGColor);
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
.cookie-popup .Button {
  background-color: transparent;

  width: 100px;
  padding: 0;
  height: 40px;

  transition: 0.2s all;
}
.cookie-popup .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.cookie-popup .button-wrapper .Empty {
  color: var(--text);
}
@media (max-width: 767px) {
  .cookie-popup {
    width: 90%;
  }
}
