.ProductWrapper .Navigator .Tab {
  overflow-y: hidden;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-width: 200px;
  padding: 12px 40px 20px 40px;
  gap: 12px;
  width: 100%;
  transition: 0.5s all;
  background: transparent;
  flex-direction: column;
  border-bottom: 1px solid #c1c1c1;
}
.Navigator .Tab:before {
  display: block;
  content: "";
  height: 2px;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  transform: scaleX(0);
  transform-origin: 0 50%;
  left: 0;
  bottom: -1px;
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}
.Navigator .Tab:hover:before {
  transform: scaleX(1);
}
.ProductWrapper .Navigator .Tab h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  color: #c1c1c1;
  line-height: 24px;
  text-align: center;
  transition: 0.5s all;
}
.ProductWrapper .Navigator .Tab p {
  color: #c1c1c1;
  transition: 0.5s all;
  height: 48px;
}

.ProductWrapper .Navigator .Active {
  position: relative;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border-bottom: 1px solid transparent;
}
.ProductWrapper .Navigator .Active:after {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  left: 0;
}

@media (max-width: 1000px) {
  .ProductWrapper .Navigator .Tab {
    width: 100%;
    justify-content: center;
  }
}
