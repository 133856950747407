.Section8 {
  display: flex;
  flex-direction: row;
  padding: 70px 0;

  background-color: var(--BGColor);
  gap: 30px;
}

.Section8 .Left {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding: 60px;
  padding-left: 0;
  width: 80%;
  align-items: flex-start;
  gap: 24px;
}
.Section8 h2 {
  margin-bottom: 0 !important;
  width: 596px;
  font-weight: 700;
  font-size: 2.625rem;
  line-height: 133.3%;

  color: var(--text);
}

.Section8 p {
  width: 596px;

  font-weight: 500;
  font-size: 1rem;
  line-height: 161.3%;
  text-align: justify;

  color: var(--text);
}

.Section8 .Right {
  flex-wrap: wrap;
  display: flex;
  column-gap: 24px;
  justify-content: flex-end;
}
.Section8 .Right .WhyUs-Card:nth-child(2),
.Section8 .Right .WhyUs-Card:last-child {
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 1000px) {
  .Section8 h2 {
    width: 100%;
  }
  .Section8 {
    padding: 0px !important;
    flex-direction: column;
  }
  .Section8 .Left,
  .Section8 .Right {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .Section8 p {
    width: 100%;
    padding: 40px 30px;
    text-align: center;
  }
}
