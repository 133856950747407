.FooterWrapper {
  background: var(--BGColor);
  display: flex;
  flex-direction: column;
  padding: 64px 96px;
}

.FooterWrapper p {
  text-align: unset;
}
.FooterWrapper a {
  color: #ee233a;
  text-decoration: none;
  transition: 0.2s all;
}
.FooterWrapper a:hover {
  color: white;
}
.FooterWrapper ul {
  list-style: none;
  padding-left: 0;
}
.col .belowLogoText {
  margin-top: 30px;
  width: 80%;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  color: #ffffff;
}
.columns {
  width: 100%;
  margin: auto;
  display: grid;
  gap: 56px;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: 33% 10% 10% 35%;
}
.NewsLetter {
  color: white !important;
}
.newsLetterInput {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.newsLetterInput .form-group input {
  color: #a1a1a1;
}
.newsLetterInput .form-group {
  height: 60px;
}
.newsLetterInput .form-group .label {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  color: #a1a1a1;
}
.newsLetterInput .label.focused-label {
  background: radial-gradient(50% 50% at 50% 50%, #322b41 0%, #0e0d0e 100%);
}
.ContactIcon {
  margin: 20px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white !important;
  gap: 10px;
  transition: 0.2s all;
}
.ContactIcon img {
  width: 24px;
}
.ContactIcon:hover {
  color: var(--HoverText) !important;
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.col .Logo {
  height: auto;
  width: 183px;
}
.col ul li a {
  color: white;
  line-height: 31px;
  font-size: 1rem;
  transition: 0.2s all;
}
.col ul li a:hover {
  color: var(--HoverText);
}
.col p {
  color: white;
  font-weight: bolder;
  font-size: 1.2rem;
}
.field span {
  color: white;
  opacity: 0.7;
}
.field p {
  padding-left: 10px;
  font-size: 1rem;
  color: #819595;
  transition: 0.2s all;
  width: 50%;
  font-weight: 300;
}
.Links {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.Links p {
  color: white;
  font-weight: bolder;
  font-size: 1.2rem;
}
.Links ul {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.Links ul img {
  cursor: pointer;
  width: 30px;
  transition: 0.2s all;
}
.Links ul img:hover {
  scale: 1.1;
}
.CopyRight {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  height: 60px;
}
.CopyRight div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CopyRight span {
  font-size: 0.75rem;
}
.CopyRight p {
  opacity: 1;
  margin: 0;
  font-size: 0.75rem;
}
.CopyRight a {
  text-decoration: underline;
  margin: 0 5px;
  color: white;
  font-size: 0.75rem;
}
.CopyRight a:hover {
  color: var(--HoverText);
}
.CopyRight p {
  color: white;
}
@media (max-width: 1000px) {
  .columns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .FooterWrapper {
    padding: 40px 20px !important;
  }
  .col .Logo {
    margin-right: unset;
  }
  .newsLetterInput {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    gap: 0;
  }
  .col {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .CopyRight p {
    text-align: center;
    margin: 0;
  }
  .CopyRight {
    padding: 20px 0px;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
  }
  .Links {
    justify-content: center;
    align-items: center;
  }
  .field p {
    width: 100%;
  }
}
