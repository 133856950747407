.Header {
  z-index: 1;
  position: relative;
  overflow-x: hidden;
  height: calc(100vh - 90px);
}
/* main page styles */
.Header.MainPage {
  background-color: #f1f1f6;
  display: flex;
  height: 100vh;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10%;
}
.Header.MainPage .Text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--text);
  width: 600px;
  padding-left: 75px;
  height: 335px;
  gap: 32px;
}
.Tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-bottom: 10px;
  min-width: 125px;
  min-height: 33px;
  width: fit-content;
  height: fit-content;
  font-size: 0.8125rem;
  line-height: 13px;
  border-radius: 30px;
  background-color: rgba(159, 112, 253, 0.1);
}
.Tag span {
  color: var(--text);

  margin: 4px 0;
  font-weight: 900;
}
.Header.MainPage .Text h1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 44.84px;
  text-align: left;
  width: 100%;
}
.Header.MainPage .Text h3 {
  width: 526px;
  height: 78px;

  font-weight: 500;
  font-size: 1rem;
  line-height: 161.3%;

  color: var(--text);
}
.Header.MainPage .Buttons {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
}
.Header.MainPage .Right {
  position: relative;
  width: 50%;
  height: 100%;
  gap: 30px;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Header.MainPage .Effect {
  position: absolute;
  width: 101px;
  height: 94px;

  gap: 0px;
}
.Header.MainPage .Effect.First {
  top: 0;
  left: 00px;
}
.Header.MainPage .Col .photo-wrapper {
  width: 288px;
  height: 288px;
  border-radius: 16px;
}
.Col .photo-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Header.MainPage .Col.First {
  height: 100%;
  padding-bottom: 30%;
}
.Header.MainPage .Col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.Header.MainPage .Effect.Second {
  bottom: 90px;
  left: 100px;
}

/* basic header styles */
.Content {
  position: relative;
  color: var(--text);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 95%;
  margin: auto;
}

.Content .BTM {
  gap: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  text-align: left;
  width: 100%;
}
.Content .BTM h1 {
  user-select: none;
  margin-bottom: 40px;
  width: 70%;
  font-size: 2.7rem;
  font-weight: 300;
}
.Content .BTM h3 {
  margin: 0;

  font-size: 1.3rem;
  width: 48%;
  font-weight: 400;
}
.Content .BTM .Button {
  cursor: pointer;
  background-color: var(--buttons);
  border: none;
  color: white;
  width: fit-content;
  font-size: 1rem;
  border-radius: var(--borderRadiusSmall);
  padding: 15px;
  font-weight: 500;
  transition: 0.2s all;
}
.Content .BTM .Button:hover {
  scale: 1.02;
}
/* service header  */
.Service {
  width: 87%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin: auto;
  align-items: flex-start;
  gap: 5%;
}
.Service .PhotoEffect {
  width: 49%;
  border-radius: 15px;
}
.Service .PhotoEffect img {
  width: 100%;
  height: 100%;
}
.Service .SideContent {
  max-height: 85vh;
  overflow-y: auto;
  z-index: 1;
  width: 49%;
}

@media (max-width: 1000px) {
  .Tag {
    margin: auto;
  }
  .Header.MainPage {
    height: fit-content;
  }
  .Header.MainPage .Text {
    margin-left: 0;
    width: 100%;
    height: unset;
    padding: 0;
    margin-top: 30px;
  }
  .Header.MainPage .Text h1 {
    font-size: 2rem;
    text-align: center;
  }
  .Header.MainPage .Text h3 {
    text-align: center;
    width: 100%;
  }
  .Header.MainPage .Buttons {
    width: 100%;
    justify-content: center;
  }
  .Header.MainPage .Buttons {
    font-size: 1.3rem;
  }
  .Content {
    height: 95%;
  }
  .Content .BTM {
    text-align: center;
  }
  .Header {
    height: 70vh;
    min-height: fit-content;
  }
  .Header.Service {
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
  }
  .Header.Service .PhotoEffect {
    height: 100%;
  }
  .Header.Service .SideContent,
  .Header.Service .PhotoEffect {
    width: 100%;
  }
  .Content .BTM h1 {
    color: white;
    font-size: 2.7rem;
    width: 100% !important;
    margin-top: 45%;
  }
  .Content .BTM h3 {
    width: 100% !important;
  }
}
