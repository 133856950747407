.Template13 .Products-wrapper {
  display: flex;
  margin-bottom: 120px;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.Template13 .ProductCard h3 {
  font-weight: 700;
  font-size: 1.2rem;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 0;
}
.Template13 .ProductCard .Line {
  width: 50%;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  height: 3px;
}
.Template13 .ProductCard .subTitle {
  color: var(--text);
  width: 80%;

  font-size: 0.9rem;
}
.Template13 .ProductCard .Description {
  text-align: center;
  font-size: 1rem;
  color: var(--text);
}

.Template13 .ProductCard a {
  margin-top: auto;
  text-align: center;
  margin-left: unset;
  text-decoration: none;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
}
.Template13 .ProductCard {
  background-color: var(--sidePagesHeaderColor);
  display: flex;
  text-align: center;
  padding: 30px;
  gap: 20px;
  border-radius: var(--borderRadiusSmall);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 380px;
  height: fit-content;
  min-height: 400px;
  border: 1px solid transparent;
  transition: 0.5s all;
}
.Template13 .ProductCard:hover {
  scale: 1.02;
  border: 1px solid var(--borders);
}
@media (max-width: 1000px) {
  .Template13 .ProductCard {
    width: 280px;
    min-height: 300px;
  }
}
