.Portal {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
  color: var(--text);
}
.Portal .SignupForm {
  width: 500px;
}
.Portal .SignupForm .NameWrapper {
  display: flex;
  gap: 30;
}
.Portal form {
  position: relative;
  width: 350px;
  margin-top: 30px;
  border: 2px solid var(--borders);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 30px;
  border-radius: var(--borderRadius);
  min-height: 300px;
  transition: 0.5s all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Portal .formItem {
  width: 100%;
  margin: 20px 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  transition: 0.5s all;
}

.Portal input[type="submit"] {
  border: none;
  width: 70%;
  margin-top: 15px;
}
.Portal input:focus {
  outline: none;
}
@media (max-width: 600px) {
  .Button-wrapper button {
    width: 100px;
  }
  .Portal form {
    width: 80%;
  }
  .Portal .SignupForm {
    width: 80%;
  }
  .Portal .SignupForm .NameWrapper {
    width: 100%;
    flex-direction: column;
  }
}
