.SideMenu {
  background-color: var(--sidePagesHeaderColor);
  width: 100vw;
  color: var(--text) !important;
  position: fixed;
  top: 0;
  border-radius: 15px;
  right: -200%;
  height: fit-content;
  z-index: 1000;
  transition: 0.5s all;
}
.SideMenu.Active {
  right: 0%;
}
.SideMenu .navbar {
  overflow-y: auto;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: unset;
}
/* Hide the scrollbar */
.navbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Customize scrollbar track */
.navbar::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Customize scrollbar thumb */
.navbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Enable scrolling */

.SideMenu .navbar a {
  color: var(--text);
}
.SideMenu .navbar,
.SideMenu .navbar ul {
  border-radius: 15px !important;
  flex-direction: column;
}
.SideMenu .navbar .dropdown {
  color: var(--text);
  width: 100%;
  display: flex;
  justify-content: center;
}

.SideMenu .navbar ul a {
  font-size: 1.2rem;
}

.SideMenu .Pricing {
  font-size: 1.2rem;
  background-color: var(--buttons);
  color: white;
  font-weight: 300;
}
.SideMenu .navbar .dropdown ul .LinkBox {
  margin: 0;
  justify-content: center;
  padding: 0;
}
.SideMenu .navbar .dropdown ul .LinkBox .Right {
  justify-content: center;
  align-items: center;
}
.SideMenu .navbar .dropdown ul .LinkBox .PageName {
  text-wrap: wrap;

  text-align: center;
}
