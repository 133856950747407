.JobOffer {
  position: relative;
  padding: 30px;
  background-color: var(--sidePagesHeaderColor);
  display: flex;
  width: 100%;
  border-radius: 8px;
  height: 134px;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 36px;
  transition: 0.5s all;
}
.JobOffer  h3{
  color: var(--text);
}
.JobOffer.Active {
  max-height: 800px;
  height: 600px;
  overflow: auto;
}
.JobOffer .Arrow {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(180deg);
  transition: 0.5s all;
  cursor: pointer;
}
.JobOffer .Arrow.Active {
  transform: rotate(0);
}

.JobHeader {
  z-index: 2;
  position: relative;
  width: 100%;
}
.JobOffer.Active .JobHeader {
  position: sticky;
  top: -30px;
  padding-top: 10px;
  left: 0;
  width: 100%;
  background-color: var(--sidePagesHeaderColor);
}
.JobOffer .Arrow.Active {
  top: 30px;
}
.JobOffer h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 152.1%;
}
.JobOffer h4 {
  font-size: 2rem;
  color: var(--text);
  margin-bottom: 30px;
}
.Exp {
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 20px;
}
.JobDetails {
  color: var(--text);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.JobDetails .Detail {
  margin: 5px 0;
  display: flex;
  width: 100%;
  gap: 8px;
}
.JobOffer .Description {
  justify-content: flex-start;
  align-items: flex-start;

  flex-direction: column;
}
.Description div p {
  text-align: left;
  margin-left: 0;
}
.Description .Description {
  z-index: 1;
  width: fit-content;
  margin: 20px 0px;
  position: relative;
  font-size: 1.5rem;
  color: var(--text);
}
.Description .Description:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--BGColor);
  bottom: -5px;
  left: 0;
}
.Description ul,
.Description ul li {
  list-style-type: disc;
  width: 100%;
  text-align: left;
}
@media (max-width: 1000px) {
}
