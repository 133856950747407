.ViewUser {
  display: flex;
  width: 100vw;
  height: 98vh;
  flex-direction: column;
  padding-top: 90px;
  margin-bottom: 100px;
}
.ViewUser h3 {
  margin-left: 20px;
}
.ViewUser .Content {
  z-index: 1;
  width: 100%;
  justify-content: left;
  flex-direction: row;
  display: flex;
  height: 90%;
}
.ViewUser .Content .Left {
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 30px;
  width: 20%;
  padding: 15px;
  padding-bottom: 50px;
  border-right: 1px solid var(--borders);
  border-left: 1px solid var(--borders);
  border-radius: var(--borderRadius);
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: auto;
}
.ViewUser .Content .Left:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}
.ViewUser .Content .Left .UserName {
  color: var(--Text);
  font-size: 1.4rem;
  margin-bottom: 10px;
}
.ViewUser .Content .Left .Email {
  text-align: left;
  font-size: 1rem;
  opacity: 0.7;
}

.ViewUser .Content .Right {
  display: flex;
  margin: auto;
  width: 60%;
}
.ViewUser .Content .Right .Card {
  width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--borders);
  border-radius: var(--borderRadius);
  padding: 30px;
  transition: 0.5s all;
}
.ViewUser .Content .Right .Card:before {
  display: none;
}
.ViewUser .Content .Right .Card p {
  color: var(--Text);

  margin: 0;
}
.ViewUser .Content .Right .Card .SubText {
  color: var(--Text);

  font-size: 0.8rem;

  opacity: 0.7;
}
.ViewUser .Content .Right .Card:hover {
  scale: 1.05;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}
.ViewUser .Content .Right .Card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}
.ViewUser .Content .Right .Card .header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.ViewUser .Content .Right .Card .header p {
  margin-bottom: 0px;

  font-weight: bolder;
}
.ViewUser .Content .Right .Card .header img {
  width: 24px;
}
