.Section7 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Section7 h2 {
  margin-bottom: 40px !important;
  font-weight: bolder;
  width: 100%;
  
  text-align: center;
}
.ProductWrapper {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ProductWrapper .swiper-horizontal > .swiper-pagination-bullets,
.ProductWrapper .swiper-pagination-bullets.swiper-pagination-horizontal,
.ProductWrapper .swiper-pagination-custom,
.ProductWrapper .swiper-pagination-fraction {
  right: 50px;
}
.ProductWrapper .Navigator {
  display: flex;
  width: 120%;
  height: 300px;
  gap: 10px;
}
.Navigator .swiper {
  width: 100vw;
  margin-right: 50px;
}
.Navigator .swiper-pagination {
  width: 80%;
}

.Display {
  padding-top: 60px;
  gap: 66px;
  display: flex;
  width: 100%;
  align-items: center;
}
.Display .Left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  width: 50%;
  gap: 32px;
}

.Display .Left .content {
  padding-left: 30px;
}
.Display .Left .content p {
  color: var(--text) !important;
}
.Display .Left h4 {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 30px;
  margin-left: 30px;
  color: var(--text);
}
.Display .Right {
  max-height: 90%;
  height: 438px;
  min-width: 580px;
  width: 47%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.Display .Button.Empty {
  margin-left: 30px;
}
.Display .Right img {
  width: 100%;
}
@media (max-width: 1000px) {
  .Display .Left h4 {
    margin: 0;
  }
  .Navigator .swiper {
    margin-right: 0;
  }
  .Display .Button.Empty {
    margin-left: 0;
  }
  .Display .Left .content {
    padding: 0;
  }
  .ProductWrapper .Navigator {
    padding-left: 0;
    flex-wrap: wrap;
    width: 100%;
    gap: 0;
  }
  .Navigator .swiper-pagination {
    width: 100%;
  }
  .ProductWrapper .Navigator .Tab {
    width: 100%;
    justify-content: center;
  }
  .Display {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .Display .Left .content p {
    text-align: center;
  }
  .Display .Left,
  .Display .Right {
    width: 100%;
    justify-content: center;
    align-items: center;
    min-width: unset;
  }
  .Display .Right img {
    width: 100%;
  }
}
