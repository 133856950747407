.About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.About section {
  padding: 80px 96px !important;
  display: flex;
  width: 100% !important;
}
.About .First {
  gap: 151px;
  flex-direction: row;
}
.About .First h1 {
  color: var(--text);
  font-weight: 600;
}
.About .First .Left {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.About .First p {
  color: var(--text);
}
.About .First .Left img {
  position: relative;
  z-index: 1;
  display: block;
  border-radius: 26px;
  width: 489px;
  height: 433px;
}

.About .First .Left::before,
.About .First .Left::after {
  content: "";
  position: absolute;

  box-sizing: border-box;
  width: 312px;
  height: 216px;
}

.About .First .Left::before {
  top: 100px;

  border-top-right-radius: 30px;
  transform: translate(38%, -61%);
  background: linear-gradient(
    218deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
}

.About .First .Left::after {
  bottom: 100px;

  border-bottom-left-radius: 30px;
  transform: translate(-38%, 61%);
  background: linear-gradient(
    211deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
}
.About .First .Right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 17px;

  width: 50%;
}
.About .Second {
  gap: 41px;
  display: flex;
}

.About .Second .Left {
  gap: 16px;
  width: 42%;
  display: flex;
  flex-direction: column;
}
.About .Second .Left h1 {
  font-weight: 600;
  color: var(--text);
}
.About .Second .Left h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 161.3%;

  color: var(--text);
}
.About p {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 161.3%;

  color: var(--text);
}
.About .Second .Right {
  width: 55%;
  display: grid;
  /* grid-template-columns: 50% 50%; */
  gap: 10px; /* Adjust as necessary */
  padding: 20px;
}
.About .Fourth {
  padding: 80px 0px !important;
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.About .Fourth  h1{
  color: var(--text);

}
.firstColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  align-items: flex-end;
}
.firstColumn .grid-item {
  height: 50%;
}
.firstColumn .grid-item :nth-child(2) {
  width: 286px;
}
.secondColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Right .secondColumn .grid-item {
  height: 80%;
}

.Second .Right .grid-item {
  position: relative;
  border-radius: 38px; /* Adjust as necessary */
}

.Second .Right .grid-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 38px; /* Adjust as necessary */
}

.About .Third {
  gap: 64px;
  padding: 40px 96px !important;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.About .Third h1{
  color: var(--text);

}
.Third .Card h3 {
  
  font-weight: 400;
}
.Third .Card p {
  color: black;
}
.Third .Card {
  background-color: #f3f3f3;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 318px;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 24px;
  border-radius: 27px;
}
.Third .Card:before {
  display: none;
}
.Third .Card:hover {
  color: white;
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  box-shadow: 0px 0px 74px rgba(229, 221, 248, 0.5);
}
.Third .Card:hover p {
  color: white;
}
.Third .Card:hover h3 {
  background: transparent;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background-clip: unset;
}
.cards-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 107px;
}
@media (max-width: 1000px) {
  .About .First {
    gap: 50px;
    text-align: center;
    flex-direction: column;
  }
  .About .image-wrapper {
    min-width: 300px;
    height: 300px;
  }
  .About .First .image-wrapper img {
    width: 100%;
    height: 100%;
  }

  .About section {
    padding: 80px 10px !important;
  }
  .About .Second {
    flex-direction: column-reverse;
  }
  .About .First .Left img {
    height: 300px;
    width: 300px;
  }
  .About .First .Left::after {
    width: 150px;
    left: -20px;
  }
  .About .First .Left::before {
    width: 150px;
    right: -20px;
  }
  .About .First .Right {
    flex-direction: column;
    display: flex;
    gap: 30px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .About .Third {
    padding: 80px 0px !important;
  }
  .About .Third .cards-wrapper {
    flex-direction: column;
    width: 90%;
  }
  .About .Second .Right {
    width: 100%;
  }
  .About .Second .Left {
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
}
