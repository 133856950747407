.ArticlePreview {
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 16px;
  align-items: flex-start;
  background-color: var(--sidePagesHeaderColor);
  color: var(--text);
  overflow: hidden;
  padding: 16px;
}
.ArticlePreview .TopPart {
  min-height: 260px;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;
  border-radius: 8px;
}
.ArticlePreview:hover .TopPart img {
  transform: scale(1.05);
}

.ArticlePreview .TopPart img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: 0.2s all;
  object-fit: contain;
}
.ArticlePreview .bottomPART {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  flex-direction: column;
  width: 100%;
}
.ArticlePreview .bottomPART .DateAdded {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 0.87rem;
  line-height: 20px;

  color: var(--text);
}
.ArticlePreview .bottomPART .Title-wrapper {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text);
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 24px;
}
.ArticlePreview .Description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.5em; /* Adjust based on your font size and line height */
  max-height: 3em; /* Adjust based on your line height to fit two lines */
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 161.3%;
  text-align: justify;
  min-height: 47px;
  color: var(--text);
}

.ArticlePreview .Link {
  background: linear-gradient(
    90deg,
    var(--gradientColor1) -21.37%,
    var(--gradientColor2) 97.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding-left: 0;
  font-weight: 700;
  font-size: 1rem;
  line-height: 16px;
}
.ArticlePreview .Link:before {
  left: 0;
}
.ArticlePreview .Link:after {
  height: unset;
}
/* .TopPart {
  display: flex;
  height: 20%;
  flex-direction: column;
}
.TopPart h2 {
  margin-bottom: 8px !important;
  font-size: 1.5rem;
}
.TopPart p {
  margin-left: 10px;
  opacity: 0.7;
  font-size: 0.8rem;
}
.Category {
  opacity: 0.7;
  text-transform: uppercase;
  margin-left: 20px;
  cursor: pointer;
}

.ArticlePreview .bottomPART {
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ArticlePreview .bottomPART .Description {
  width: 50%;
  text-align: left;
}
.bottomPART img {
  border-radius: var(--borderRadius);
  width: 40%;
}
.ExtraData {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.ExtraData span {
  display: flex;
  align-items: flex-start;
  font-size: 0.9rem;
}
.ExtraData img {
  width: 18px;
  margin: 0 10px;
} */

@media (max-width: 600px) {
  .ArticlePreview {
    min-width: unset;
    width: 90%;
    margin: 0;
  }
  .ArticlePreview .Link:after {
    display: none;
  }
  .ArticlePreview .TopPart {
    width: 100%;
    min-width: unset;
  }
  .ArticlePreview .bottomPART {
    flex-direction: column;
  }
  .ArticlePreview .bottomPART img {
    width: 100%;
  }
  .ArticlePreview .bottomPART .Title-wrapper {
    width: 100%;
    font-size: 1.4rem;
  }
  .ArticlePreview .bottomPART .Description {
    width: 100%;
    font-size: 1rem;
  }
  .ArticlePreview .ExtraData {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
  }
}
