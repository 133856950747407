.Section4 {
  flex-direction: row-reverse;
  margin-right: auto;
  overflow: hidden;
  justify-content: center;
  min-height: 665px;
  height: fit-content;
}
.Section4 .Left {
  flex-direction: column;
  width: 604px;
  height: fit-content;
  display: flex;
  gap: 16px;
  padding-left: 2.5%;
}
.Section4 .Left h2 {
  color: var(--text);
  font-weight: bolder;
  text-align: left;
  margin-bottom: 0 !important;
}
.Section4 .Left p {
  font-weight: 500;
  line-height: 161.3%;
  font-size: 1rem;
  color: var(--text);
  text-align: left;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  margin: 16px 0;
}
.Section4 .Right {
  position: relative;
  width: 604px;
  height: 511px;
  display: flex;
}
.Section4 .Right .Main {
  border-radius: var(--borderRadius);
  width: 456px;
  height: 456px;
  overflow: hidden;
  margin: auto;
}
.Section4 .Right:hover .Main img {
  transform: scale(1.05);
}
.Section4 .Right .Main img {
  transition: 0.5s all;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Section4 .Right .Effect {
  position: absolute;
  justify-content: center;
  display: flex;
}
.Section4 .Right .Effect img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
}
.Section4 .Right .Effect.First {
  top: 0;
  right: 0;
  width: 171px;
  height: 156px;
}
.Section4 .Right .Effect.Second {
  bottom: 0;
  left: -50px;
  width: 178px;
  height: 223px;
}
.Section4 .Right::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1;
}
@media (max-width: 1000px) {
  .Section4 {
    overflow-y: hidden;
    flex-direction: column;
    height: fit-content;
  }
  .Section4 .Left {
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .Section4 .Left h2 {
    text-align: center;
  }
  .Section4 .Left p {
    text-align: center;
    font-size: 14.4px;
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
  }
}
