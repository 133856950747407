.TemplateGeneral .Team-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  .TemplateGeneral .Team-wrapper {
    flex-direction: column;
  }
}
