.TemplateGeneral .Cards-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}
.pricing .Pricing-container {
  width: 100%;
  gap: 30px;
}
.pricing .Pricing-container .PlanDesc {
  font-style: italic;
  margin: 0;
  margin: 10px;
  width: 100%;
}
.TemplateGeneral .Pricing-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin: auto;
}
@media (max-width: 1000px) {
  .TemplateGeneral .Pricing-container {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}
