.ViewMember.Edit {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ViewMember.Edit .Hosting {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 85%;
  gap: 20px;
}
.ViewMember.Edit .Hosting .formItem {
  width: 40%;
}

.ViewMember .content {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  gap: 10px;
}
.ViewMember .content .Picture {
  width: 37%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ViewMember .content .Picture img {
  border-radius: var(--borderRadius);
  width: 90%;
}
.ViewMember .content .Details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  padding-left: 30px;
}
.ViewMember .content .Details h6 {
  text-align: left;
  letter-spacing: 1px;
  font-weight: 300;
}
.ViewMember .content .Details .phone {
  margin-right: 10px;
  margin-left: auto;
  background-color: transparent;
  border: 1px solid var(--borders);
  border-radius: var(--borderRadiusSmall);
  padding: 5px 10px;
}
.ViewMember .content .Details h5 {
  font-size: 2rem;
  font-weight: 300;
}
.ViewMember .content .Details img {
  width: 25px;
  height: 25px;
}
.ViewMember .content .Details .OverView {
  border: none;
  box-shadow: none;
  width: 95%;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
@media (max-width: 1000px) {
  .ViewMember .content {
    flex-direction: column;
  }
  .ViewMember .content .Picture {
    width: 100%;
  }
  .ViewMember .content .Details {
    border-top: 1px solid var(--borders);
    border-left: none;
    width: 100%;
    padding-top: 20px;
    padding-left: 0;
  }

  .ViewMember .content .Details .Socials {
    margin-top: 20px;
    padding: 0 20px;
  }
}
